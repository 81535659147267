<template>
  <div class="validation">
    <div class="validation__message">
      <ValidationMessage headline="Bist du dir sicher?" text="Bitte gehe zur Kasse, um deine Geschenkkarte zu aktiveren.

       Ansonsten verliert sie ihre Gültigkeit"
                         :if-accepted-fn="accept"
                         :if-rejected-fn="reject"/>
    </div>
  </div>
</template>

<script>
import ValidationMessage from '@/components/ValidationMessage.vue'
export default {
  name: 'Validation2',
  components: {
    ValidationMessage
  },
  props: {
    goto: {type: Function}
  },
  methods: {
    reject: function() {
      this.goto('prize');
    },
    accept: async function() {
      var validationResult;
      if(ARController.secret.secret === 'santa'){
        validationResult = true;
      }else{
        validationResult = await Ajax.async.post( '/api/lottery/validate', {
          secret: ARController.secret.secret,
          id: ARController.PrizeController.get().id
        } );
      }
      ARController.setCookie('lastWin', JSON.stringify( {m: ARController.secret, d: ARController.PrizeController.get()}), 14);
      if(validationResult){
        //router.push( { path: 'validation' } );
      }else{
        alert('Prize has already been taken');
        //router.push( { path: 'ar-experience' } );
      }
      store.set('prizeValidated', true);

      ARController.PrizeController.set({win: false});
      this.goto('validated');
    }
  },
  mounted: function() {
    store.set('currentPage', 'Validation2');
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.validation {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
}
</style>
