<template>
  <div class="validation-message">
    <h1 class="validation-message__headline">{{ headline }}</h1>
    <div class="validation-message__text">
      <p>{{ text }}</p>
    </div>
    <div class="validation-message__buttons">
      <Button :to="ifAccepted" :onclick="ifAcceptedFn" value="Ja"/>
      <Button :to="ifRejected" :onclick="ifRejectedFn" value="Nein" pattern="secondary"/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
export default {
  name: 'ValidationMessage',
  props: {
    headline: {
      type: String
    },
    text: {
      type: String
    },
    ifAccepted: {
      type: String
    },
    ifAcceptedFn: {
      type: Function
    },
    ifRejectedFn: {
      type: Function
    },
    ifRejected: {
      type: String
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.validation-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
  width: 100%;
  max-width: 290px;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
}

.validation-message__headline {
  margin: 0 0 15px;
  text-transform: uppercase;
  font-family: $accent-font;
  font-weight: 500;
  font-size: 27px;
}

.validation-message__text {
  margin-bottom: 25px;
  width: 100%;
  white-space: pre-line;
  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.validation-message__buttons {
  display: grid;
  grid-gap: 16px;
  width: 200px;
}
</style>
